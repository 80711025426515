body {
  background-color: #f0f0f0; /* ページ全体の背景色を設定 */
}

.App h1 {
  color: #333; /* タイトルのテキストカラーを設定 */
  font-size: 24px; /* タイトルのフォントサイズを設定 */
  margin-bottom: 20px; /* タイトルの下の余白を設定 */
}

.App form {
  background-color: #fff; /* フォームの背景色を設定 */
  padding: 20px; /* フォームの内部パディングを設定 */
  border: 1px solid #ddd; /* フォームの境界線を設定 */
}

.App label {
  display: block; /* ラベルをブロック要素として表示 */
  margin-bottom: 10px; /* ラベルの下の余白を設定 */
}

.App input[type='number'] {
  width: 100%; /* 数値入力フィールドを幅いっぱいに広げる */
  padding: 10px; /* 入力フィールドの内部パディングを設定 */
  border: 1px solid #ccc; /* 入力フィールドの境界線を設定 */
  border-radius: 5px; /* 入力フィールドの角を丸める */
}

.App button {
  background-color: #007bff; /* ボタンの背景色を設定 */
  color: #fff; /* ボタンのテキストカラーを設定 */
  padding: 10px 20px; /* ボタンのパディングを設定 */
  border: none; /* ボタンの境界線をなくす */
  border-radius: 5px; /* ボタンの角を丸める */
  cursor: pointer; /* ボタンにポインターのカーソルを設定 */
}

.App button:hover {
  background-color: #0056b3; /* ホバー時のボタンの背景色を設定 */
}

.App ul {
  list-style: none; /* リストのマーカーを非表示にする */
  padding: 0; /* リストの内部パディングを設定 */
}

.App li {
  margin-bottom: 10px; /* 各結果アイテムの下の余白を設定 */
  font-size: 18px; /* 結果アイテムのフォントサイズを設定 */
}
